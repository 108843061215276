if (document.querySelector(".downloadlist")) {
  const downloadlist = document.querySelector(".downloadlist");
  const toggleButtons = downloadlist.querySelector(".plus-minus-toggle").querySelectorAll("span");
  const checkbox = downloadlist.querySelector(".show-additional-entries");

  toggleButtons.forEach((button) => {
    button.addEventListener("keydown", (event) => {
      if (button.style.display === "none") return;
      if (event.key === "Enter") {
        checkbox.checked = !checkbox.checked;
        const visibleButton = checkbox.checked ? toggleButtons[1] : toggleButtons[0];
        visibleButton.focus();
      }
    });
  });
}
